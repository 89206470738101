.about-row {
  &__image {
    max-width: 32%;
    /* @media only screen and (max-width: 700px) {
      max-width: 100%;
    } */
    padding: 2px;
    border-radius: 20px;
  }
}
