@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.booking-calendar {
  display: inline-block;
  border: 3px solid $primary;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  .calendar-day {
    text-align: center;
    display: inline-block;
    font-size: 16px;
    width: $calendar-day-width;
    line-height: $calendar-day-width;
    @media only screen and (max-width: $small) {
      width: $calendar-day-width-small;
      line-height: $calendar-day-width-small;
    }
    user-select: none;
  }

  .changeover {
    background-color: $changeover-color;
    border-radius: $calendar-day-width;
    @media only screen and (max-width: $small) {
      border-radius: $calendar-day-width-small;
    }
    cursor: pointer;
  }

  .changeoverFont {
    font-weight: bold;
  }

  .not-available-day {
    background-color: $not-available-color;
    cursor: default;
  }

  .unselectable-day {
    cursor: default;
  }

  .outside-of-month-day {
    font-weight: 200;
  }

  .selected-day {
    color: white;
    background-color: $accent;
    cursor: pointer;
  }

  .start-and-end-of-period {
    border-radius: $calendar-day-width/2 $calendar-day-width/2 $calendar-day-width/2 $calendar-day-width/2;
    @media only screen and (max-width: $small) {
      border-radius: $calendar-day-width-small/2 $calendar-day-width-small/2 $calendar-day-width-small/2 $calendar-day-width-small/2;
    }
  }

  .start-of-period {
    border-radius: $calendar-day-width/2 0 0 $calendar-day-width/2;
    @media only screen and (max-width: $small) {
      border-radius: $calendar-day-width-small/2 0 0 $calendar-day-width-small/2;
    }
  }

  .end-of-period {
    border-radius: 0 $calendar-day-width/2 $calendar-day-width/2 0;
    @media only screen and (max-width: $small) {
      border-radius: 0 $calendar-day-width-small/2 $calendar-day-width-small/2 0;
    }
  }

  .unselected-day {
    cursor: pointer;
  }
}
