@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.icon-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 60px;
  align-items: center;
  justify-items: center;

  &__item {
    text-align: center;
    max-width: 250px;
  }
}
