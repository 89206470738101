@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.booking-calendar-header {
  display: inline-block;
  width: calc(7 * #{$calendar-day-width});
  @media only screen and (max-width: $small) {
    width: calc(7 * #{$calendar-day-width-small});
  }
  background-color: $primary;
  padding: 5px 0;
  border: 0;
  border-width: 1px;
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  user-select: none;

  &__month {
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    width: 77%;
    padding: 10px 0;
  }

  &__button {
    display: inline-block;
    width: 10%;
    cursor: pointer;

    // TODO: use FA icon instead?
    font-size: 12px;

    i {
      font-size: 20px;
      color: white;

      &:hover {
        filter: brightness(120%);
      }
    }

    &--left {
      text-align: left;
      padding-left: 10px;
      color: white;
    }

    &--right {
      text-align: right;
      padding-right: 10px;
      color: white;
    }
  }
}
