// $fa-font-path : '../../node_modules/font-awesome/fonts';

$maxwidth: 1050px;
$x-small: 376px;
$small: 500px;
$medium: 920px;

// Calendar
$calendar-day-width-small: 36px;
$calendar-day-width: 55px;

$font-size: 16px;

// Footer
$footer-min-height: 125px;
