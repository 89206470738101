@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.navigation-bar {
  background-color: #00174f;
  padding: 10px;

  &__app-logo {
    height: 66px;
    position: relative;
    top: 10px;
    margin-left: 10px;
  }

  &__company-name {
    display: inline-block;
    color: white;
    font-size: 3vw;
    margin: 20px;
  }

  &__list {
    @media only screen and (max-width: 1250px) {
      display: none;
    }
    float: right;
  }

  &__list-item {
    display: inline-block;
    margin: 10px;

    a {
      text-transform: uppercase;
      font-size: 1vw;
      font-weight: bold;
      text-decoration: none;
    }

    a:link,
    a:visited {
      color: white;
    }
    a:hover {
      color: orange;
    }
    a:active {
      color: white;
    }
    a:focus {
      color: white;
    }

    &--selected {
      border-bottom: 5px solid orange;
    }
  }
}

.mobile-nav {
  z-index: 6;
  color: white;
  position: absolute;
  right: 20px;
  top: 44px;
  font-size: 18px;
  cursor: pointer;

  @media only screen and (min-width: 1251px) {
    display: none;
  }

  .mobile-nav__icon {
    position: relative;
    top: -14px;
    right: -8px;

    &--bump-right {
      right: -2px;
    }
  }

  .mobile-nav__text {
    position: relative;
    top: -15px;
    font-size: 12px;
  }
}
