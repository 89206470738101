@import '../../../assets/styles/_colors.scss';


.textbox {
  &__label {
    display: block;
    cursor: pointer;
  }

  &__input {
    border: 2px solid $primary;
    width: 100%;
    padding: 8px;
    font-size: 16px;

    &--error {
      border-color: red;
    }
  }

  &__error {
    color: red;
    text-align: right;
    margin-top: 10px;
  }
}
