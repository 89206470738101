@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.payment-form {
  &__description {
    font-size: 18px;
    background-color: $secondary-light;
    padding: 5px 20px;
    border-radius: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &__card-details {
    background-color: white;
    border: 3px solid $primary;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &__error {
    color: red;
    font-size: 20px;
  }
}
