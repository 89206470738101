@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.booking-calendar-column-headings {
  &__label {
    display: inline-block;
    width: $calendar-day-width;
    @media only screen and (max-width: $small) {
      width: $calendar-day-width-small;
    }
    text-align: center;
    padding: 5px 0;
    user-select: none;
  }
}
