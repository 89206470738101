@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.sliding-menu {
  background-color: $secondary-light;
  height: 100%;
  position: fixed;
  width: 90%;
  right: 0;
  top: 0;
  z-index: 5;

  @media only screen and (min-width: 1251px) {
    display: none;
  }

  &--hidden {
    display: none;
  }
}
