@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.about-icon {
  &__image {
    color: $primary;
    color: $secondary;
  }
  &__text {
    color: black;
  }
}
