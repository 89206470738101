@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.booking-calendar-footer {
  display: inline-block;
  text-align: center;
  height: 14px;
  border-top: 3px solid $primary;
  padding-top: 12px;
  height: 50px;
  width: 100%;
  user-select: none;

  .calendar-legend-item-box {
    display: inline-block;
    border: solid;
    border-width: 0;
    width: 20px;
    height: 20px;
    text-align: center;

    .changeover {
      border-radius: 10px;
    }
  }

  .calendar-legend-item-meaning {
    color: $primary;
    display: inline-block;
    font-size: 13px;
    padding: 0 6px 10px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}
