@import './assets/styles/_colors.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.--hidden {
  visibility: hidden;
}

.--display-none {
  display: none;
}

.content {
  min-height: calc(100vh - 277px);
}

.button {
  text-transform: uppercase;
  padding: 10px 20px;
  cursor: pointer;
  border: 0;
  font-weight: 800;
  font-size: 18px;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
  transition: background-color .3s;
  background-color: $button;


  &--primary {
    color: #ecf0f1;
    background-color: $button;
  }

  &--secondary {
    color: $button;
    background-color: $button-background;
  }
}

.--form-group {
  margin-bottom: 1em;
}


.--uppercase {
  text-transform: uppercase;
}

.--bold {
  font-weight: 800;
}

.--white {
  color: white;
}

.--align-left {
  text-align: left;
}
.--align-center {
  text-align: center;
}
.--align-right {
  text-align: right;
}

.--position-relative {
  position: relative;
}

.--bump-down {
  position: relative;
  top: 8px;
}

a {
  color: red;

  a:link {
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  a:active {
    background-color: transparent;
    text-decoration: underline;
  }
}

ul {
  li {
    padding: 5px;
  }
}
