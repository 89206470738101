@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.approve-booking-form {
  background-color: white;

  border: 3px solid $primary;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
