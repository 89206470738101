@import './assets/styles/_layout.scss';

.spinner {
  animation: spin infinite 3s linear;
  /*You can increase or decrease the timer (5s) to
   increase or decrease the speed of the spinner*/
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
