@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.payment-options {
  background-color: $secondary-light;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  input {
    width: 20px;
    margin: 20px 20px 15px 10px;
    -ms-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }

  .full-amount {
    font-weight: 800;
  }

  &__list {
    li {
      margin: 20px;
    }
  }
}
