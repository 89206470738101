@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.contact-element {
  &__icon {
    color: $secondary;
  }

  &__label {
    display: block;
  }
}

// .twitter {
//   color: #55acee;
// }

// .instagram {
//   color: #e95950;
// }

// .facebook {
//   color: #3b5998;
// }

// .pinterest {
//   color: #cb2027;
// }
