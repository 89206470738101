@import '../../assets/styles/_colors.scss';

.home {
  &__banner {
    width: 100%;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 5%;
      max-width: 350px;
    }
  }

  &__content {
    min-height: calc(100vh - 548px);
  }

  &__visitWales {
      height: 100px;
  }
}
