@import '../../../assets/styles/_colors.scss';

.bookings-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
}

.bookings-td, .bookings-th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bookings-tr:nth-child(even) {
  background-color: #dddddd;
}
