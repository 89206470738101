@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 30px;
  background-color: #000000;
  top: 0;
  opacity: 0.85;
  z-index: 100;
}
