@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.info-banner {
  text-align: center;
  margin: 20px;
  padding: 50px;
  font-weight: 600;
  opacity: 0.8;
  font-size: 20px;

  &__error {
    background: red;
    color: white;
  }

  &__success {
    background: green;
    color: white;
  }
}
