@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_variables.scss';

.bookable-period {
  &__details {
    font-size: 18px;

    position: relative;
    background-color: $secondary-light;
    // width: calc(7 * #{$calendar-day-width});
    // @media only screen and (max-width: $small) {
    //   width: calc(7 * #{$calendar-day-width-small});
    // }

    padding: 20px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .bookable-period__dates {
      font-weight: 700;
      padding: 5px;
    }

    .bookable-period__duration {
      font-weight: 400;
      padding: 5px;
    }

    .bookable-period__rate {
      font-weight: 900;
      padding: 5px;
    }

    .bookable-period__previous-rate {
      font-size: 16px;
      font-weight: 500;
      padding: 5px;
    }
  }

  &__unselected {
    border: 5px dashed lightgrey;
    color: black;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    opacity: 0.5;
  }
}
